import React from "react";
import {
  BarChart,
  ReferenceLine,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Cell,
  LabelList,
  ResponsiveContainer,
} from "recharts";

// const data = [
//   {
//     name: "Paracetamol 500mg Tablet (Value Brand)",
//     uv: (40 / 80) * 90,
//   },
//   {
//     name: "Chlorphenamine + Paracetamol + Phenylephrine",
//     uv: 72,
//   },
//   {
//     name: "Mefenamic acid 500mg Tablet (Value Brand)",
//     uv: 28,
//   },
//   {
//     name: "Hyoscine 10mg Tablet (Value Brand)",
//     uv: 65,
//   },
//   {
//     name: "Omeprazole 20mg Capsule (Value Brand)",
//     uv: 43,
//   },
// ]

const COLORS = ["#f39151", "#9ec5c5", "#3d8b8b"];
const firstLayout = { top: -18, left: -50 };
const otherLayout = { top: -27, left: -50, bottom: 3 };

const InventoryMonitoring = ({ data, isFirst }) => {
  return (
    <ResponsiveContainer width="90%" height={40}>
      <BarChart
        data={data}
        layout="vertical"
        margin={isFirst ? firstLayout : otherLayout}
      >
        <ReferenceLine x={135} stroke="gray" strokeDasharray="3 3" />
        <ReferenceLine x={180} stroke="gray" strokeDasharray="3 3" />
        <ReferenceLine x={0} stroke="gray" strokeDasharray="3 3" />
        {isFirst ? (
          <XAxis
            type="number"
            ticks={[0, 135, 180]}
            orientation="top"
            tick={{ fontSize: 9 }}
            tickSize={3}
            domain={[0, 180]}
          />
        ) : (
          <XAxis
            type="number"
            tick={false}
            orientation="top"
            domain={[0, 180]}
            axisLine={false}
          />
        )}
        <YAxis type="category" dataKey="product" tick={false} />
        <Tooltip cursor={{ fill: "#e9e8e8" }} />
        <Bar dataKey="daysLeft" fill="#0D6D6E">
          {data.map((entry, index) => {
            const color =
              entry.daysLeft < 30
                ? COLORS[0]
                : entry.daysLeft < 60
                ? COLORS[1]
                : COLORS[2];
            return <Cell fill={color} key={index} />;
          })}
          <LabelList dataKey="daysLeft" />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default InventoryMonitoring;
