import React, { Fragment } from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCapsules, faFirstAid } from "@fortawesome/free-solid-svg-icons";

import InventoryRow from "./InventoryRow";
import DataTable from "../../Templates/DataTable";
import Pagination from "../../Templates/Pagination";
import Loader from "../../Templates/Loader";

const InventoryTabs = ({
  medicineInventory,
  suppliesInventory,
  activeTab,
  setActiveTab,
  isLoading,
}) => {
  const medicineTitles = [
    "Rank",
    "Medicine",
    "Stocks Left",
    "Days Inventory Left",
  ];
  const suppliesTitles = [
    "Rank",
    "Supplies",
    "Stocks Left",
    "Days Inventory Left",
  ];

  return (
    <Fragment>
      <div className="tabs is-boxed">
        <ul className="field is-grouped">
          <li
            className={classnames("button mx-1 pt-1 is-borderless", {
              "has-background-primary has-text-white":
                activeTab === "medicines",
            })}
            onClick={() => setActiveTab("medicines")}
          >
            <span className="icon">
              <FontAwesomeIcon icon={faCapsules} />
            </span>
            <span>Medicine Inventory</span>
          </li>
          <li
            className={classnames("button mx-1 pt-1 is-borderless", {
              "has-background-primary has-text-white": activeTab === "supplies",
            })}
            onClick={() => setActiveTab("supplies")}
          >
            <span className="icon">
              <FontAwesomeIcon icon={faFirstAid} />
            </span>
            <span>Supplies Inventory</span>
          </li>
        </ul>
      </div>

      {activeTab === "medicines" && (
        <>
          {isLoading ? (
            <Loader />
          ) : (
            <DataTable headerTitles={medicineTitles}>
              {medicineInventory.map((item, index) => {
                return <InventoryRow item={item} index={index} />;
              })}
            </DataTable>
          )}
        </>
      )}

      {activeTab === "supplies" && (
        <>
          {isLoading ? (
            <Loader />
          ) : (
            <DataTable headerTitles={suppliesTitles}>
              {suppliesInventory.map((item, index) => {
                return <InventoryRow item={item} index={index} />;
              })}
            </DataTable>
          )}
        </>
      )}
    </Fragment>
  );
};

export default InventoryTabs;
