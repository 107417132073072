import React, { useState, useEffect, useContext } from "react";
import Layout from "../../layout/Layout";
import Menu from "../../components/Inventory/Menu";
import InventoryTabs from "../../components/Inventory/InventoryLogs/InventoryTabs";

import api from "../../services/api";
import AppContext from "../../context/AppContext";
import SEO from "../../layout/Seo";

const FullInventory = () => {
  const [medicineInventory, setMedicines] = useState([]);
  const [suppliesInventory, setSupplies] = useState([]);
  const [medicinePages, setMedicinePages] = useState(0);
  const [suppliesPages, setSuppliesPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [activeTab, setActiveTab] = useState("medicines");
  const [isLoading, setLoading] = useState(false);
  const appContext = useContext(AppContext);

  useEffect(() => {
    setLoading(true);
    api
      .get(
        `/inventory/report/?type=1&clinic=${sessionStorage.getItem("clinicId")}`
      )
      .then(response => {
        setMedicines(response.data.results);
        setMedicinePages(Math.ceil(response.data.count / 30));
        setLoading(false);
      });
    api
      .get(
        `/inventory/report/?type=2&clinic=${sessionStorage.getItem("clinicId")}`
      )
      .then(response => {
        setSupplies(response.data.results);
        setSuppliesPages(Math.ceil(response.data.count / 30));
      });
  }, []);

  const searchData = search => {
    search = search.replace(/\//g, "%2F");
    search = search.replace(/\+/g, "%2B");
    setSearchQuery(search);
    setIsSearching(true);
    setLoading(true);
    if (activeTab === "medicines")
      api
        .get(
          `/inventory/report/?search=${search}&type=1&clinic=${sessionStorage.getItem(
            "clinicId"
          )}`
        )
        .then(response => {
          setMedicines(response.data.results);
          setMedicinePages(Math.ceil(response.data.count / 30));
          setLoading(false);
        });
    else if (activeTab === "supplies")
      api
        .get(
          `/inventory/report/?search=${search}&type=2&clinic=${sessionStorage.getItem(
            "clinicId"
          )}`
        )
        .then(response => {
          setSupplies(response.data.results);
          setSuppliesPages(Math.ceil(response.data.count / 30));
          setLoading(false);
        });
  };

  const resetSearch = values => {
    setIsSearching(false);
    if (values.product) values.product = "";
    setSearchQuery("");
    setLoading(true);
    if (activeTab === "medicines")
      api
        .get(
          `/inventory/report/?type=1&clinic=${sessionStorage.getItem(
            "clinicId"
          )}`
        )
        .then(response => {
          setMedicines(response.data.results);
          setMedicinePages(Math.ceil(response.data.count / 30));
          setLoading(false);
        });
    else if (activeTab === "supplies")
      api
        .get(
          `/inventory/report/?type=2&clinic=${sessionStorage.getItem(
            "clinicId"
          )}`
        )
        .then(response => {
          setSupplies(response.data.results);
          setSuppliesPages(Math.ceil(response.data.count / 30));
          setLoading(false);
        });
  };

  const medicineCallbackFunction = pageNumber => {
    setLoading(true);
    api
      .get(
        `/inventory/report/?type=1&clinic=${sessionStorage.getItem(
          "clinicId"
        )}&page=${pageNumber}${searchQuery ? `&search=${searchQuery}` : ""}`
      )
      .then(response => {
        setMedicines(response.data.results);
        setLoading(false);
      });
  };

  const suppliesCallbackFunction = pageNumber => {
    setLoading(true);
    api
      .get(
        `/inventory/report/?type=2&clinic=${sessionStorage.getItem(
          "clinicId"
        )}&page=${pageNumber}${searchQuery ? `&search=${searchQuery}` : ""}`
      )
      .then(response => {
        setSupplies(response.data.results);
        setLoading(false);
      });
  };

  const changeTabs = tabName => {
    setActiveTab(tabName);
    setLoading(true);
    if (tabName === "medicines")
      api
        .get(
          `/inventory/report/?type=1&clinic=${sessionStorage.getItem(
            "clinicId"
          )}${searchQuery ? `&search=${searchQuery}` : ""}`
        )
        .then(response => {
          setMedicines(response.data.results);
          setMedicinePages(Math.ceil(response.data.count / 30));
          setLoading(false);
        });
    else if (tabName === "supplies")
      api
        .get(
          `/inventory/report/?type=2&clinic=${sessionStorage.getItem(
            "clinicId"
          )}${searchQuery ? `&search=${searchQuery}` : ""}`
        )
        .then(response => {
          setSupplies(response.data.results);
          setSuppliesPages(Math.ceil(response.data.count / 30));
          setLoading(false);
        });
  };

  return (
    <Layout pageTitle="Full Inventory List" hasBanner>
      <SEO title="Full Inventory List" />
      <Menu
        searchData={searchData}
        resetSearch={resetSearch}
        appContext={appContext}
        isSearching={isSearching}
      />
      <br />
      <InventoryTabs
        suppliesInventory={suppliesInventory}
        medicineInventory={medicineInventory}
        activeTab={activeTab}
        setActiveTab={changeTabs}
        medicinePages={medicinePages}
        medicineCallbackFunction={medicineCallbackFunction}
        suppliesPages={suppliesPages}
        suppliesCallbackFunction={suppliesCallbackFunction}
        isLoading={isLoading}
      />
    </Layout>
  );
};

export default FullInventory;
