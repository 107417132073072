import React from "react";
import InventoryMonitoring from "../../Dashboard/Graphs/InventoryMonitoring";

import classNames from "classnames";
import styles from "../inventory.module.scss";

const InventoryRow = ({ item, index, callbackFunction }) => {
  let { productTitle, currentQuantity, unitOfMeasurement } = item;

  return (
    <tr className="is-selectable" onClick={callbackFunction}>
      <td className="has-text-centered">{index + 1}</td>
      <td className={classNames(styles.medicine)}>{productTitle}</td>
      <td className="has-text-centered">{currentQuantity}</td>
      <td>
        <InventoryMonitoring
          data={[{ product: item.product, daysLeft: item.daysLeft }]}
          isFirst={index === 0}
        />
      </td>
    </tr>
  );
};

export default InventoryRow;
